import { normalizeHref } from "../core";

export function navigate(
  href: string,
  options: { origin: string; region: { id: string; name?: string } },
) {
  const nextHref = normalizeHref(href, {
    origin: options.origin,
    region: options.region,
  });

  window.location.href = nextHref;
}
